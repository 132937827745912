<template>
  <div id="app">
    <el-container>
      <el-main>
        <h2>{{ company }}</h2>
        <br />
        <el-row gutter="40">
          <el-col span="8" v-for="(site, index) in sites.sites" :key="index">
            <DemoCard :pageName="site.pageName" :url="site.url" />
          </el-col>
        </el-row>
      </el-main>
      <el-footer>
        <el-row>
          <el-col span="24">
            Copyright &copy; {{ currentYear }} {{ company }} All rights
            reserved.
          </el-col>
          <el-col span="24">
            <el-link href="https://beian.miit.gov.cn/" target="_blank"
              >备案号：{{ siteICP }}</el-link
            >
          </el-col>
        </el-row>
      </el-footer>
    </el-container>
  </div>
</template>

<script>
import DemoCard from "./components/DemoCard.vue";

export default {
  name: "App",
  components: {
    DemoCard,
  },
  beforeCreate() {
    document.title = "苏州千祥信息技术有限公司";
  },
  data() {
    return {
      sites: require("./assets/siteList.json"),

      currentYear: new Date().getFullYear(),
      company: "苏州千祥信息技术有限公司",
      siteICP: "苏ICP备2023002704号-1",
    };
  },
};
</script>

<style>
* {
  margin: 0;
  padding: 0;
}

:root {
  background-color: rgb(233, 233, 233);
}

.el-container {
  min-height: 100vh;
}

.el-main,
.el-footer {
  text-align: center;
}

.el-footer {
  /* background-color: rgb(198, 198, 198); */
  /* line-height: 30px; */
  padding: 10px;
}
</style>