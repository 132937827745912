<template>
  <div class="main">
    <el-card shadow="hover" class="card">
      <el-link :href="url" target="_blank">
        <h3>{{ pageName }}</h3>
      </el-link>
    </el-card>
  </div>
</template>

<script>
export default {
  name: "DemoCard",
  props: {
    pageName: String,
    url: String,
  },
};
</script>

<style>
.card {
  margin-bottom: 20px;
  display: flex;
  flex-wrap: wrap;
}
.el-card {
  min-width: 100%;
  height: 100%;
  margin-right: 20px;
  transition: all 0.5s;
}
</style>